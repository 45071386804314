import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

import HeroHeader from "../components/heroHeader"

const NotFoundPage = () => {
  const {
    sanityNoPage: { heroHeader },
  } = useStaticQuery(graphql`
    query sanityNoPage {
      sanityNoPage {
        heroHeader {
          mainContent {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          subContent {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          featureImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          waveColor
        }
      }
    }
  `)

  return (
    <Layout>
      <HeroHeader data={heroHeader} />
    </Layout>
  )
}

export default NotFoundPage
